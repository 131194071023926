import { deviceParametersGroup, deviceTypes, powerStatus, timeSeconds } from 'config/constant';
import dayjs from 'dayjs';
import { DeviceEvent } from 'interfaces/devices/DeviceEvents.interface';
import { Device } from 'interfaces/devices/Devices.interface';

/**
 * Checks if the device is of type 'sensor'.
 * @param {Device} device - The device object to check.
 * @returns {boolean} True if the device type is 'sensor', otherwise false.
 */
export const isDeviceSensor = (device: Device): boolean => {
  return device.deviceType === deviceTypes.SENSOR;
};

/**
 * Checks if the device is of type 'switch'.
 * @param {Device} device - The device object to check.
 * @returns {boolean} True if the device type is 'switch', otherwise false.
 */
export const isDeviceSwitch = (device: Device): boolean => {
  return device.deviceType === deviceTypes.SWITCH;
};

/**
 * Checks if the device is offline based on the last seen time.
 * A device is considered offline if it hasn't been seen in the last 15 minutes.
 * @param {Device} device - The device object to check.
 * @returns {boolean} True if the device is offline, otherwise false.
 */
export const isDeviceOffline = (input: Device | null | undefined | number): boolean => {
  if (!input) return true;
  const currentTimeInSeconds = Date.now() / 1000;
  const lastSeenTimeInSeconds = typeof input === 'number' ? input : input?.lastSeenAt?.seconds?.low;

  if (!lastSeenTimeInSeconds) return true;

  const timeDifference = currentTimeInSeconds - lastSeenTimeInSeconds;

  return timeDifference > timeSeconds.QUARTER_HOUR;
};

export const getDeviceStatusTooltipMessage = (isOffline: boolean) => {
  if (isOffline) return 'This device is not sending messages.';
  return 'This device is activly sending update messages.';
};

/**
 * Returns devices power status
 * A device power status can be : "BATTERY_LOW", "BATTERY_MEDIUM", "BATTERY_HIGH", "POWER_NO_INFO", "POWER_CABLE"
 * @param {Device} device
 * @returns {string}
 */
export const getPowerStatus = (device: Device | null | undefined) => {
  if (!device) return powerStatus.POWER_NO_INFO;

  const batteryLevel = Number(device.latestEvent?.parameters?.battery) || null;

  //If no battery level => there is no power info or there is cable power
  //TODO need to differentiate those 2 cases
  if (!batteryLevel) return powerStatus.POWER_NO_INFO;
  if (batteryLevel > 100) return powerStatus.POWER_CABLE;
  else if (batteryLevel > 70) return powerStatus.BATTERY_HIGH;
  else if (batteryLevel <= 70 && batteryLevel > 25) return powerStatus.BATTERY_MEDIUM;
  else if (batteryLevel > 0) return powerStatus.BATTERY_LOW;
  else return powerStatus.BATTERY_EMPTY;
};

export const getDevicePowerTooltipMessage = (devicePowerStatus: string) => {
  if (
    devicePowerStatus === powerStatus.BATTERY_HIGH ||
    devicePowerStatus === powerStatus.BATTERY_MEDIUM
  ) {
    return 'This device is charged and usable';
  } else if (devicePowerStatus === powerStatus.BATTERY_LOW) {
    return 'This device is low on battery';
  } else if (devicePowerStatus === powerStatus.BATTERY_EMPTY) {
    return 'This device has run out of battery';
  } else if (devicePowerStatus === powerStatus.POWER_CABLE) {
    return 'This device is connected to a power source';
  }
  return 'This device has no battery information yet';
};

export const getDeviceUptimeTooltipMessage = (hasUptime: boolean) => {
  return hasUptime
    ? 'The device is active for this time period'
    : 'Device is offline, uptime is unavailable';
};

/**
 * Returns message with the last timestamp at when the devices emmited a reading or 'Never
 * If show from now is true - it adds to the message  the string of relative time from now.
 * eg: 2024-08-21 12:30:58 (2 months ago)
 * @param device
 * @param showFromNow
 */

export const getDeviceLastSeen = (
  input: Device | null | undefined | number,
  showFromNow: boolean
) => {
  let lastSeenUnix = null;
  if (typeof input === 'number') {
    lastSeenUnix = input;
  } else if (input) {
    lastSeenUnix = input.lastSeenAt?.seconds?.low;
  }
  const lastSeenAtMessage = lastSeenUnix
    ? dayjs.unix(lastSeenUnix).format('YYYY-MM-DD HH:mm:ss')
    : 'Never';
  const timmeFromNow =
    showFromNow && lastSeenUnix ? ' (' + dayjs.unix(Number(lastSeenUnix)).fromNow() + ')' : '';

  if (!input) return 'Never';
  return lastSeenAtMessage + timmeFromNow;
};

/**
 * Returns the uptime for a device:
 * eg: 10 hours 48 minutes
 * @param device
 * @param lastOfflineTimestamp
 * @returns
 */
export const getUptime = (device?: Device | null, lastOfflineTimestamp?: number) => {
  const isOffline = isDeviceOffline(device);

  if (isOffline || !device || !lastOfflineTimestamp) return '-';

  const currentTime = dayjs().unix();
  const upTime = currentTime - Number(lastOfflineTimestamp);

  const totalTimeDays = Math.floor(upTime / (3600 * 24));
  const totalTimeHours = Math.floor((upTime % (3600 * 24)) / 3600);
  const totalTimeMinutes = Math.floor((upTime % 3600) / 60);

  let uptimeString = '';
  if (totalTimeDays > 0) {
    uptimeString += `${totalTimeDays} day${totalTimeDays > 1 ? 's' : ''}`;
  }
  if (totalTimeHours > 0) {
    uptimeString += `${uptimeString.endsWith(': ') ? '' : ' '}${totalTimeHours} hour${totalTimeHours > 1 ? 's' : ''}`;
  }
  if (totalTimeMinutes > 0) {
    uptimeString += `${uptimeString.endsWith(': ') ? '' : ' '}${totalTimeMinutes} minute${totalTimeMinutes > 1 ? 's' : ''}`;
  }

  return upTime > 0 ? uptimeString : '';
};

export const getSensorVersion = (deviceEvent: any) => {
  if (deviceEvent?.parameters?.temperature) {
    return 'V2';
  } else if (deviceEvent?.parameters?.temperatures) {
    return 'V3';
  }
  return null;
};

export const hasActuators = (deviceEvents: DeviceEvent[]) => {
  let hasActuators = false;
  deviceEvents.forEach((event) => {
    if (event.parameters.actuators) {
      hasActuators = true;
    }
  });
  return hasActuators;
};

export const getDeviceSensors = (deviceEvents: DeviceEvent[]) => {
  let deviceSensors: string[] = [];
  let isPowerPlug = false;
  deviceEvents.forEach((event: DeviceEvent) => {
    if (event.parameters) {
      const parameters = Object.keys(event.parameters);
      if (
        parameters.includes(deviceParametersGroup.BATTERY) &&
        event?.parameters?.battery != null &&
        event.parameters.battery > 100
      ) {
        isPowerPlug = true;
      }
      deviceSensors = [...deviceSensors, ...parameters];
    }
  });
  let paramtersSet = Array.from(new Set(deviceSensors));
  let sensors = paramtersSet.filter((sensor) => sensor !== deviceParametersGroup.ACTUATORS);
  if (isPowerPlug) {
    sensors = sensors.filter((sensor) => sensor !== deviceParametersGroup.BATTERY);
  }

  return sensors;
};
