import { ReactComponent as Overview } from 'assets/icons/overview.svg';
import { ReactComponent as Light } from 'assets/icons/light.svg';
import { ReactComponent as AirHumidity } from 'assets/icons/air-humidity.svg';
import { ReactComponent as Battery } from 'assets/icons/battery-charging.svg';
import { ReactComponent as SoilMoisture } from 'assets/icons/soil-moisture.svg';
import { ReactComponent as Pressure } from 'assets/icons/pressure.svg';
import { ReactComponent as Temperature } from 'assets/icons/temperature.svg';
import { ReactComponent as FlowRate } from 'assets/icons/flow-meter.svg';
import styles from './GraphTabs.module.scss';
import { DEVICE_VERSION, deviceParametersV2, deviceParametersV3 } from 'config/constant';

export const getGraphTab = (deviceVersion: string | null, parameters: string[]) => {
  let deviceParam = deviceVersion === DEVICE_VERSION.V3 ? deviceParametersV3 : deviceParametersV2;
  const graphTabs = [
    {
      label: 'Overview',
      key: '1',
      graphParameter: 'parameters.' + deviceParam.LUMINOSITY,
      graphLabel: '',
      icon: <Overview />,
      className: 'overviewTab',
    },
    {
      label: 'Luminosity',
      key: '2',
      graphParameter: 'parameters.' + deviceParam.LUMINOSITY,
      graphLabel: 'Luminosity',
      icon: <Light className={styles.tabIcon} />,
      className: 'luminosity',
    },
    {
      label: 'Temperature',
      key: '3',
      graphParameter: 'parameters.' + deviceParam.TEMPERATURE,
      graphLabel: 'Temperature',
      icon: <Temperature className={styles.tabIcon} />,
      className: 'temperature',
    },
    {
      label: 'Soil Moisture',
      key: '4',
      graphParameter: 'parameters.' + deviceParam.SOIL_MOISTURE,
      graphLabel: 'Soil Moisture',
      icon: <SoilMoisture className={styles.tabIcon} />,
      className: 'soilMoisture',
    },
    {
      label: 'Pressure',
      key: '5',
      graphParameter: 'parameters.' + deviceParam.PRESSURE,
      graphLabel: 'Pressure',
      icon: <Pressure className={styles.tabIcon} />,
      className: 'pressure',
    },
    {
      label: 'Air Humidity',
      key: '6',
      graphParameter: 'parameters.' + deviceParam.AIR_HUMIDITY,
      graphLabel: 'Air Humidity',
      icon: <AirHumidity className={styles.tabIcon} />,
      className: 'airHumidity',
    },
    {
      label: 'Battery',
      key: '7',
      graphParameter: 'parameters.' + deviceParam.BATTERY,
      graphLabel: 'Battery',
      icon: <Battery className={styles.tabIcon} />,
      className: 'battery',
    },
    {
      label: 'Flow Rate',
      key: '8',
      graphParameter: 'parameters.' + deviceParam.FLOW_RATES,
      graphLabel: 'Flow Rate',
      icon: <FlowRate className={styles.tabIcon} />,
      className: 'flowRate',
    },
  ];

  return graphTabs.filter((tab) =>
    parameters.includes(tab.graphParameter.replace('parameters.', ''))
  );
};
