import {
  DeviceEventFromServer,
  DeviceEvent,
  DeviceParamaters,
} from 'interfaces/devices/DeviceEvents.interface';
import { baseApi } from './baseApi.service';

export const deviceEventsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getDeviceEvents: builder.mutation({
      query: ({ deviceId, from, to, resolution }) => ({
        url: `/deviceEvents?deviceId=${deviceId}&from=${from}&to=${to}&resolution=${resolution}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      }),
      transformResponse: (response: any) => {
        const devicesList = response.map((event: DeviceEventFromServer) => {
          const parameters = event.parameters as unknown as DeviceParamaters;

          let deviceEvent: DeviceEvent = {
            timestamp: event.timestamp,
            parameters: parameters as DeviceParamaters,
          };
          return deviceEvent;
        });

        return devicesList as DeviceEvent[];
      },
    }),
    getLastOfflineTimestamp: builder.query<
      number,
      { deviceId: string; deviceProfileId: string | null }
    >({
      query: ({ deviceId, deviceProfileId }) => ({
        url: `/deviceOfflineTimestamp?deviceId=${deviceId}&deviceProfileId=${deviceProfileId}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      }),
      transformResponse: (response: any) => {
        return response;
      },
    }),
  }),
  overrideExisting: false,
});

export const { useGetDeviceEventsMutation, useGetLastOfflineTimestampQuery } = deviceEventsApi;
