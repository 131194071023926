import { deviceParametersV3, RESOLUTIONS, timeSeconds, unitTypes } from 'config/constant';
import savitzkyGolay from 'ml-savitzky-golay';
import { line, curveNatural } from 'd3-shape';
import dayjs from 'dayjs';
import { DateRangeUnix } from 'interfaces/Time.interface';

/**
 * Returns the appropriate unit for a given parameter.
 * @param {string} parameter - The parameter for which to determine the unit.
 * @returns {string} The unit associated with the parameter, or an empty string if no match is found.
 */
export const getUnit = (parameter: string): string => {
  if (parameter.includes(deviceParametersV3.LUMINOSITY)) {
    return unitTypes.LUX;
  }
  if (parameter.includes(deviceParametersV3.PRESSURE)) {
    return unitTypes.PA;
  }
  if (
    parameter.includes(deviceParametersV3.SOIL_MOISTURE) ||
    parameter.includes(deviceParametersV3.AIR_HUMIDITY)
  ) {
    return unitTypes.PERCENT;
  }
  if (parameter.includes(deviceParametersV3.TEMPERATURE)) {
    return unitTypes.CELSIUS;
  }
  if (parameter.includes(deviceParametersV3.FLOW_RATES)) {
    return unitTypes.FLOW_RATE;
  }

  return '';
};

/**
 * Formats a Unix timestamp based on the provided date range.
 * The format changes depending on the duration of the date range:
 * - Less than or equal to 1 day: 'HH:mm'
 * - Less than or equal to 1 week: 'ddd HH:mm'
 * - Less than or equal to 1 month: 'DD/MM'
 * - More than 1 month: 'DD/MM/YYYY'
 * @param {number} timestamp - The Unix timestamp to format.
 * @param {DateRangeUnix} tooltipDateRange - The start and end of the date range.
 * @returns {string} The formatted date string.
 */
export const formatTick = (timestamp: number, tooltipDateRange: DateRangeUnix): string => {
  const { startDate, endDate } = tooltipDateRange;
  const duration = endDate - startDate;

  if (duration <= timeSeconds.ONE_DAY) {
    return dayjs.unix(timestamp).format('HH:mm');
  } else if (duration <= timeSeconds.ONE_WEEK) {
    return dayjs.unix(timestamp).format('ddd HH:mm');
  } else if (duration <= timeSeconds.ONE_MONTH) {
    return dayjs.unix(timestamp).format('DD/MM');
  }

  return dayjs.unix(timestamp).format('DD/MM/YYYY');
};

/**
 * Determines the appropriate CSS class for a brush component based on the duration of a date range.
 * The class varies depending on the length of the date range:
 * - Less than or equal to 1 day: 'last-day-brush'
 * - Less than or equal to 1 week: 'last-week-brush'
 * - Less than or equal to 1 month: 'last-month-brush'
 * - More than 1 month: 'longer-period-brush'
 * @param {DateRangeUnix} tooltipDateRange - The start and end of the date range.
 * @returns {string} The CSS class name.
 */
export const getBrushClass = (tooltipDateRange: DateRangeUnix): string => {
  const { startDate, endDate } = tooltipDateRange;
  const duration = endDate - startDate;

  if (duration <= timeSeconds.ONE_DAY) {
    return 'last-day-brush';
  } else if (duration <= timeSeconds.ONE_WEEK) {
    return 'last-week-brush';
  } else if (duration <= timeSeconds.ONE_MONTH) {
    return 'last-month-brush';
  }

  return 'longer-period-brush';
};

/**
 * Applies a Savitzky-Golay smoothing filter to a set of 2D points.
 *
 * This function is  used to smooth out data points in a graph, especially
 * when the data contains noise or abrupt fluctuations. The smoothing is applied
 * separately to the x and y coordinates of the points.
 *
 * @param points - An array of points where each point is represented as a tuple [x, y].
 *   - `x`: The x-coordinate (e.g., time or an independent variable).
 *   - `y`: The y-coordinate (e.g., value or dependent variable).
 * @param smoothWindowSize - The window size to use for the Savitzky-Golay filter.
 *   This determines the number of adjacent points to use for smoothing.
 *   It must be an odd number and at least 5. If an even number is provided,
 *   it will be incremented to the next odd number.
 * @param polinomialDegree - The degree of the polynomial to use for the Savitzky-Golay filter.
 *   A higher degree allows for more flexible fitting but might overfit the data.
 *
 * @returns A new array of smoothed points. If the input array has fewer points
 *   than the window size, the original points are returned without modification.
 */
export const applyHysteresisEffect = (
  points: [number, number][],
  smoothWindowSize: number,
  polinomialDegree: number
) => {
  // Ensure the window size is at least 5 and is an odd number.
  const options = { windowSize: smoothWindowSize, polynomial: polinomialDegree };
  if (options.windowSize < 5) {
    options.windowSize = 5;
  }
  if (options.windowSize % 2 === 0) {
    options.windowSize += 1;
  }

  // Separate the points into x and y arrays.
  const xPoints = points.map((point) => point[0]);
  const yPoints = points.map((point) => point[1]);

  // Apply the Savitzky-Golay filter if the number of points is sufficient.
  if (xPoints.length >= options.windowSize && yPoints.length >= options.windowSize) {
    const smoothXPoints = savitzkyGolay(xPoints, 1, {
      windowSize: options.windowSize,
      polynomial: options.polynomial,
      derivative: 0,
    });
    const smoothYPoints = savitzkyGolay(yPoints, 1, {
      windowSize: options.windowSize,
      polynomial: options.polynomial,
      derivative: 0,
    });

    // Combine the smoothed x and y points back into [x, y] pairs.
    return smoothXPoints.map((x, index) => [x, smoothYPoints[index]]);
  } else {
    // If there are not enough points for smoothing, return the original points.
    return points;
  }
};

export const getResolution = (zoomDomain: [number, number] | null) => {
  if (!zoomDomain) {
    return null;
  }
  const from = zoomDomain[0];
  const to = zoomDomain[1];

  const interval = (to - from) / (60 * 60 * 24);

  if (interval >= 10) {
    return RESOLUTIONS.DAILY;
  } else if (interval < 10 && interval >= 0.8) {
    return RESOLUTIONS.HOURLY;
  } else if (interval < 0.8 && interval > 0.2) {
    return RESOLUTIONS.TEN_MIN;
  } else {
    return RESOLUTIONS.RAW;
  }
};

export const getNextFinerResolution = (resolution: string | null) => {
  if (resolution === RESOLUTIONS.DAILY) {
    return RESOLUTIONS.HOURLY;
  } else if (resolution === RESOLUTIONS.HOURLY) {
    return RESOLUTIONS.TEN_MIN;
  } else if (resolution === RESOLUTIONS.TEN_MIN) {
    return RESOLUTIONS.RAW;
  } else return null;
};

export const customHysteresisCurve: any = (
  context: any,
  smoothWindowSize: number,
  polinomialDegree: number
): {
  areaStart(): void;
  areaEnd(): void;
  lineStart(): void;
  lineEnd(): void;
  point(x: number, y: number): void;
} => {
  const curve = curveNatural(context); // Use d3's natural curve to get the default curve methods
  let allPoints: [number, number][] = []; // Initialize an empty array to store all points
  let lineStarted = false;
  // Custom generator object to handle different parts of the curve generation

  const customGenerator = {
    areaStart() {},
    areaEnd() {},

    lineStart() {
      allPoints = [];
      lineStarted = true;
      if ('lineStart' in curve) {
        curve.lineStart();
      }
    },
    lineEnd() {
      if (lineStarted) {
        // Apply hysteresis effect to the collected points
        const smoothedPoints = applyHysteresisEffect(allPoints, smoothWindowSize, polinomialDegree);
        const d3Line = line<[number, number]>()
          .x((d) => d[0])
          .y((d) => d[1]);

        const pathContext = context as unknown as { _: string };
        pathContext._ += d3Line(smoothedPoints as any) || ''; // Append the line path with hysteresis points to the context
      }
    },
    point(x: number, y: number) {
      allPoints.push([x, y]); // Add the point to the points array
      if ('point' in curve) {
        curve.point(x, y); // Call the original curve's point method if it exists
      }
    },
  };

  return customGenerator; // Return the custom generator
};
