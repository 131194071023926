import { baseApi } from './baseApi.service';

export const actuatorsService = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getActuators: builder.query<any, { tenantId: string }>({
      query: ({ tenantId }) => ({
        url: `/actuators?tenantId=${tenantId}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      }),
      transformResponse: (response: any) => {
        const result = response || null;
        if (result && Array.isArray(result)) {
          result.sort((a, b) => {
            if (a.deviceName.toLowerCase() < b.deviceName.toLowerCase()) return -1;
            if (a.deviceName.toLowerCase() > b.deviceName.toLowerCase()) return 1;
            return 0;
          });
        }
        return result;
      },
      providesTags: (result, error, { tenantId }) => [{ type: 'Actuators', id: tenantId }],
    }),
    setActuator: builder.mutation<
      { tenantId: string; devEui: string; data: number[]; actuator_names: string[] },
      { tenantId: string; devEui: string; data: number[]; actuator_names: string[] }
    >({
      query: ({ tenantId, devEui, data, actuator_names }) => {
        return {
          url: '/actuators',
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
          body: JSON.stringify({ tenantId, devEui, data, actuator_names }),
        };
      },
    }),
    editActuator: builder.mutation<
      { tenantId: string; devEui: string; name: string; index: number },
      { tenantId: string; devEui: string; name: string; index: number }
    >({
      query: ({ tenantId, devEui, name, index }) => {
        return {
          url: '/actuators/name',
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
          body: JSON.stringify({ tenantId, devEui, name, index }),
        };
      },
      invalidatesTags: (result, error, { tenantId }) => [{ type: 'Actuators', id: tenantId }],
    }),
  }),
});

export const { useGetActuatorsQuery, useSetActuatorMutation, useEditActuatorMutation } =
  actuatorsService;
