import Table from 'components/common/Table/Table';
import { useEffect, useState } from 'react';
import { useGetDevicesQuery } from 'services/devices.service';
import { Device } from 'interfaces/devices/Devices.interface';
import { ColumnGroupType, ColumnType } from 'antd/es/table';
import { useAppSelector } from 'store/hooks';
import { setDevices } from 'store/slices/devices.slice';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Result } from 'antd';
import Spinner from 'components/common/Spin/Spin';
import { tenantSelector } from 'store/slices/tenants.slice';

import { DeviceStatus } from 'components/specific/DeviceInfo/DeviceStatus/DeviceStatus';
import { PowerStatus } from 'components/specific/DeviceInfo/PowerStatus/PowerStatus';
import { getDeviceLastSeen } from 'utils/device.utils';
import { ReactComponent as SensorsIllustration } from 'assets/icons/sensors_illustration.svg';

const DevicesView = () => {
  const [tableDeviceList, setTableDeviceList] = useState<any[]>([{}]);
  const tenantId = useAppSelector(tenantSelector).selectedTenant?.tenantId as string;
  const dispatch = useDispatch();
  const navigator = useNavigate();

  const {
    data: devicesData,
    error,
    isLoading,
  } = useGetDevicesQuery(
    { tenantId },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const getFormatedDevices = (devicesList: Device[]): any[] => {
    return devicesList.map((device) => {
      return {
        id: device.devEui,
        name: device.name,
        description: device.description,
        status: <DeviceStatus device={device} />,
        lastSeen: getDeviceLastSeen(device, false),
        battery: <PowerStatus device={device} />,
      };
    });
  };

  useEffect(() => {
    if (devicesData) {
      setTableDeviceList(getFormatedDevices(devicesData));
      dispatch(setDevices(devicesData));
    }
  }, [devicesData, dispatch]);

  const columns: (ColumnGroupType<any> | ColumnType<any>)[] = [
    {
      title: 'Name',
      dataIndex: 'name',
      width: '30%',
      render: (text: string, record) => {
        return <b>{text}</b>;
      },
    },
    {
      title: 'Last Seen',
      dataIndex: 'lastSeen',
      width: '30%',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: '30%',
    },
    {
      title: 'Power',
      dataIndex: 'battery',
    },
  ];

  return (
    <>
      <Spinner spinning={isLoading}>
        {!error && tableDeviceList.length > 0 && (
          <>
            <Table
              data={tableDeviceList}
              columns={columns}
              onRowClick={(record: any) => {
                navigator(`/device?id=${record.id}`);
              }}
            />
          </>
        )}
      </Spinner>
      {error && <Result status='500' subTitle='No devices found' />}

      {tableDeviceList.length === 0 && !isLoading && !error && (
        <div className='emptyContainer'>
          <SensorsIllustration className='sensorIllustration' />
          <div className='emptyContent'>
            <p className='emptyTitle'>No Devices Connected</p>
            <p className='emptyDescription'>
              No devices are currently associated with this tennant. <br />
              Ensure that a gateway is properly set up and functioning. Devices will appear here,
              once an administrator has added them to your tennant and the gateway is active.
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default DevicesView;
