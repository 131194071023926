import { baseApi } from './baseApi.service';

export const timelineService = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getTimeline: builder.query<any, { tenantId: string; page: number }>({
      query: ({ tenantId, page }) => ({
        url: `/actuators/timeline?tenantId=${tenantId}&page=${page}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      }),
      transformResponse: (response: any) => {
        const result = response || null;

        return result;
      },
    }),
  }),
});

export const { useGetTimelineQuery } = timelineService;
