import { ConfigProvider, Divider, Layout } from 'antd';
import Sidebar from 'components/common/Sidebar/Sidebar';
import Header from 'components/specific/Header/Header';
import { ReactComponent as TimelineIcon } from 'assets/icons/list-timeline2.svg';
import { ReactComponent as ValvesIcon } from 'assets/icons/pipe2.svg';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import Devices from 'views/Devices/Devices';
import ProfilePage from 'views/ProfilePage/ProfilePage';
import styles from './BaseLayout.module.scss';
import DeviceEvents from 'views/DeviceEvents/DeviceEvents';
import { useDispatch, useSelector } from 'react-redux';
import { Device } from 'interfaces/devices/Devices.interface';
import { setSelectedDevice } from 'store/slices/devices.slice';
import { styled } from 'styled-components';
import { ReactComponent as Sensor } from 'assets/icons/sensor.svg';
import { routePaths } from 'routes/Routes';
import { isDeviceOffline } from 'utils/device.utils';
import variables from '../styles/variables.module.scss';
import Valves from 'views/Actuators/Actuators';
import Timeline from 'views/Timeline/Timeline';

const { Content } = Layout;

const menuItems = [
  {
    key: '1',
    label: 'Valves',
    path: routePaths.VALVES,
    icon: <ValvesIcon className={`${styles.sensorIcon} ${styles.valvesIcon}`} />,
    title: 'Valves',
    content: <Valves />,
  },
  {
    key: '2',
    label: 'Devices',
    path: routePaths.SENSORS,
    icon: <Sensor className={`${styles.sensorIcon} ${styles.sensorListIcon}`} />,
    title: 'Devices',
    content: <Devices />,
  },
  {
    key: '3',
    label: 'Timeline',
    path: routePaths.TIMELINE,
    icon: <TimelineIcon className={`${styles.sensorIcon} ${styles.timelineListIcon}`} />,
    title: 'Timeline',
    content: <Timeline />,
  },
];

const StyledDivider = styled(Divider)`
  && {
    border-color: #ddd;
  }
`;
interface BaseLayoutProps {
  user: any;
  selectedRoute: string;
  children?: React.ReactNode;
}

const BaseLayout: React.FC<BaseLayoutProps> = ({ user }) => {
  const [collapsed, setCollapsed] = useState(false);
  const [selectedKey, setSelectedKey] = useState<string | null>(null);
  const navigate = useNavigate();
  const { devicesList } = useSelector((state: any) => state.devices);
  const [searchParams] = useSearchParams();

  const location = useLocation();
  const dispatch = useDispatch();
  let selectedDevice = useSelector((state: any) => state.devices.selectedDevice);
  const [selectedDeviceOffline, setSelectedDeviceOffline] = useState(false);

  useEffect(() => {
    const selectedItem = menuItems.find((item) => item.path === location.pathname);
    let key = selectedItem?.key || null;
    if (location.pathname === '/') {
      key = '1';
    }

    setSelectedKey(key);
  }, [location.pathname]);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const hasOfflineDevices = devicesList.some((device: Device) => isDeviceOffline(device));

  useEffect(() => {
    const deviceId = searchParams.get('id');
    const device = devicesList.find((device: Device) => device.devEui === deviceId);
    if (device) {
      setSelectedDeviceOffline(isDeviceOffline(device));
      dispatch(setSelectedDevice(device));
    }
  }, [location.search, devicesList, dispatch, searchParams]);

  let content, title;
  switch (location.pathname) {
    case routePaths.VALVES:
      content = menuItems[0].content;
      title = menuItems[0].title;
      break;
    case routePaths.SENSORS:
      content = menuItems[1].content;
      title = menuItems[1].title;
      break;
    case routePaths.TIMELINE:
      content = menuItems[2].content;
      title = menuItems[2].title;
      break;
    case routePaths.DEVICE:
      content = (
        <DeviceEvents
          deviceId={selectedDevice?.devEui}
          key={selectedDevice?.devEui}
          isOffline={selectedDeviceOffline}
        />
      );
      title = (
        <div>
          <span>Devices</span> <StyledDivider type='vertical' /> <span>{selectedDevice?.name}</span>
        </div>
      );
      break;
    case routePaths.PROFILE:
      content = <ProfilePage />;
      title = 'Profile';
      break;

    default:
      content = menuItems[0].content;
      title = menuItems[0].title;
      break;
  }

  const handleMenuItemSelect = (item: any) => {
    navigate(item.path);
  };

  return (
    <Layout className={styles.containerLayout}>
      <ConfigProvider
        wave={{ disabled: true }}
        theme={{
          token: {
            colorPrimary: `${variables.primaryColor}`,
          },
        }}
      >
        <Sidebar
          collapsed={collapsed}
          toggleCollapsed={toggleCollapsed}
          menuItems={menuItems}
          onItemSelect={handleMenuItemSelect}
          selectedKey={selectedKey}
        />
        <Layout className={styles.contentLayout}>
          <Header hasUser={user ? true : false} text={title} hasWarnings={hasOfflineDevices} />
          <Content
            id='scrollableContent'
            style={{
              height: 'calc(100vh - 64px)',
              overflow: 'auto',
            }}
            className={styles.siteLayoutBackground}
          >
            {content}
          </Content>
          {/* {process.env.REACT_APP_VERSION && ( */}
          <div className={styles.footer}>Version: {process.env.REACT_APP_VERSION}</div>
          {/* )} */}
        </Layout>
      </ConfigProvider>
    </Layout>
  );
};

export default BaseLayout;
