import React from 'react';
import { ColumnGroupType, ColumnType } from 'antd/es/table';
import StyledTable from './Table.styles';

interface DataTableProps {
  data: any;
  columns: (ColumnGroupType<any> | ColumnType<any>)[];
  onRowClick?: (record: any, rowIndex: number) => void;
  rowClassName?: string; // Add rowClassName prop
  title?: () => JSX.Element; // Add title prop
  className?: string;
}

const DataTable: React.FC<DataTableProps> = ({
  data,
  columns,
  onRowClick,
  rowClassName,
  title,
  ...restProps
}) => (
  <StyledTable
    columns={columns}
    dataSource={data}
    pagination={false}
    rowHoverable
    rowClassName={rowClassName} // Add rowClassName prop
    title={title} // Add title prop
    className='table'
    onRow={(record, rowIndex) => {
      return {
        onClick: (event) => {
          if (onRowClick) {
            onRowClick(record, rowIndex ?? 0);
          }
        },
      };
    }}
    {...restProps}
  />
);

export default DataTable;
